import TablePagination from '@mui/material/TablePagination'
import validator from 'validator'
import { tablePaginationClasses as classes } from '@mui/base/TablePagination'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { styled } from '@mui/material/styles'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import {
  Alert,
  AppBar,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CardMedia,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { updateCourseByTeacher, updateTeacher } from 'src/redux/slices/teachingSlice/teacherSlice'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { getFreeWilayaList } from 'src/redux/slices/tableSlice/wilayaSlice'
import { getFreeCountriesList } from 'src/redux/slices/tableSlice/countrySlice'
import { getFreeNationalitiesList } from 'src/redux/slices/tableSlice/nationalitySlice'

import { getMinInstitutionInfo } from 'src/redux/slices/tableSlice/institutionSlice'
import { registrationStudent } from 'src/redux/slices/teachingSlice/studentSlice'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}

const focusedStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

const Registration = () => {
  const onDrop = useCallback((acceptedFiles) => {}, [])

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    fileRejections,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: { 'application/pdf': ['.pdf'] },
    maxFiles: 1,
    maxSize: 20480000,
  })

  useEffect(() => {
    if (acceptedFiles?.length !== 0) {
      setFormValues({
        ...formValues,
        FILES: { error: false, errorMessage: '' },
      })
    } else {
      setFormValues({
        ...formValues,
        FILES: {
          error: true,
          errorMessage:
            lang === 'Français'
              ? `Vous devez uploader vos documents`
              : 'You must upload your documents',
        },
      })
    }
    setCurrentStudent({ ...currentStudent, FILES: acceptedFiles })
  }, [acceptedFiles])

  const acceptedFileItems = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ))

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  )

  const { minInstitutionData } = useSelector((state) => state.institution)
  const wilaya = useSelector((state) => state.wilaya)
  const country = useSelector((state) => state.country)
  const nationality = useSelector((state) => state.nationality)
  const { isLoading } = useSelector((state) => state.student)
  const [submit, setSubmit] = useState(false)
  const lang = 'Français'

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getMinInstitutionInfo())
  }, [dispatch])

  const [initWilayaValue, setInitWilayaValue] = useState(null)
  const [listWilaya, setListWilaya] = useState([])
  const [initWilayaResidenceValue, setInitWilayaResidenceValue] = useState(null)
  const [initWilayaBacValue, setInitWilayaBacValue] = useState(null)
  const [initCountryValue, setInitCountryValue] = useState(null)
  const [listCountry, setListCountry] = useState([])
  const [initNationalityValue, setInitNationalityValue] = useState(null)
  const [listNationality, setListNationality] = useState([])

  const [currentStudent, setCurrentStudent] = useState({
    NOM_ETU: '',
    PRENOM_ETU: '',
    NOM_ETU_AR: '',
    PRENOM_ETU_AR: '',
    DT_NAIS_ETU: null,
    LIEU_NAIS_ETU: '',
    LIEU_NAIS_ETU_AR: '',
    WIL_NAIS_ETU: null,
    PAYS_NAIS_ETU: 1,
    SEXE_ETU: 'Masculin',
    NATIONALITE_ETU: 1,
    ADR_ETU_AR: '',
    ADR_ETU: '',
    WIL_ADR_ETU: null,
    TEL_ETU: '',
    EMAIL_ETU: '',
    MATRICULE_BAC: '',
    ANNEE_BAC: null,
    WIL_BAC_ETU: null,
    FILIERE_BAC: null,
    MOYENNE_BAC: null,
    PRESUME: '0',
    ANNEE_PRESUME: null,
    ETABLISSEMENT: '',
    FILES: null,
  })

  const [formValues, setFormValues] = useState({
    NOM_ETU: {
      error: true,
      errorMessage:
        lang === 'Français' ? `Nom de l'étudiant est requis` : 'Student last name is required',
    },
    PRENOM_ETU: {
      error: true,
      errorMessage:
        lang === 'Français' ? `Prénom de l'étudiant est requis` : 'Student first name is required',
    },
    MATRICULE_BAC: {
      error: true,
      errorMessage:
        lang === 'Français' ? `Matricule du bac est requis` : 'Baccalaureate number is required',
    },
    ANNEE_BAC: {
      error: true,
      errorMessage:
        lang === 'Français'
          ? `L'année du bac est requise`
          : 'The year of the baccalaureate is required',
    },
    EMAIL_ETU: {
      error: true,
      errorMessage: lang === 'Français' ? `Votre email est requis` : 'Your email is required',
    },
    FILES: {
      error: true,
      errorMessage:
        lang === 'Français'
          ? `Vous devez uploader vos documents`
          : 'You must upload your documents',
    },
  })

  useEffect(() => {
    dispatch(getFreeWilayaList())
    dispatch(getFreeCountriesList())
    dispatch(getFreeNationalitiesList())
  }, [])

  useEffect(() => {
    setListWilaya(
      wilaya?.wilayaData.map((n) => {
        return {
          ID_WIL: n.ID_WIL,
          LIB_WIL: n.LIB_WIL || '',
          LIB_WIL_EN: n.LIB_WIL_EN || '',
        }
      }),
    )
  }, [wilaya])

  useEffect(() => {
    setListCountry(
      country?.countryData.map((n) => {
        return {
          ID_PAYS: n.ID_PAYS,
          LIB_PAYS: n.LIB_PAYS || '',
          LIB_PAYS_EN: n.LIB_PAYS_EN || '',
        }
      }),
    )
  }, [country])

  useEffect(() => {
    setListNationality(
      nationality?.nationalityData.map((n) => {
        return {
          ID_NAT: n.ID_NAT,
          LIB_NAT: n.LIB_NAT || '',
          LIB_NAT_EN: n.LIB_NAT_EN || '',
        }
      }),
    )
  }, [nationality])

  const handleInputChange = (event) => {
    event.preventDefault()
    const { name, value } = event.target

    switch (name) {
      case 'NOM_ETU':
        if (!value.trim()) {
          setFormValues({
            ...formValues,
            [name]: {
              error: true,
              errorMessage:
                lang === 'Français'
                  ? `Nom de l'étudiant est requis`
                  : 'Student last name is required',
            },
          })
        } else {
          setFormValues({
            ...formValues,
            [name]: { error: false, errorMessage: '' },
          })
        }
        break
      case 'PRENOM_ETU':
        if (!value.trim()) {
          setFormValues({
            ...formValues,
            [name]: {
              error: true,
              errorMessage:
                lang === 'Français'
                  ? `Prénom de l'étudiant est requis`
                  : 'Student first name is required',
            },
          })
        } else {
          setFormValues({
            ...formValues,
            [name]: { error: false, errorMessage: '' },
          })
        }
        break
      case 'MATRICULE_BAC':
        if (!value.trim()) {
          setFormValues({
            ...formValues,
            [name]: {
              error: true,
              errorMessage:
                lang === 'Français'
                  ? `Matricule du BAC est requis`
                  : 'Baccalaureate number is required',
            },
          })
        } else {
          setFormValues({
            ...formValues,
            [name]: { error: false, errorMessage: '' },
          })
        }
        break
      case 'ANNEE_BAC':
        !value.trim()
          ? setFormValues({
              ...formValues,
              [name]: {
                error: true,
                errorMessage:
                  lang === 'Français'
                    ? `L'année du bac est requise`
                    : 'The year of the baccalaureate is required',
              },
            })
          : setFormValues({
              ...formValues,
              [name]: { error: false, errorMessage: '' },
            })

        break
      case 'EMAIL_ETU':
        !validator.isEmail(value)
          ? setFormValues({
              ...formValues,
              [name]: {
                error: true,
                errorMessage:
                  lang === 'Français' ? `Votre email est requis` : 'Your email is required',
              },
            })
          : setFormValues({
              ...formValues,
              [name]: { error: false, errorMessage: '' },
            })

        break
      case 'FILES':
        if (!value) {
          setFormValues({
            ...formValues,
            [name]: {
              error: true,
              errorMessage:
                lang === 'Français'
                  ? `Vous devez uploader vos documents`
                  : 'You must upload your documents',
            },
          })
        } else {
          setFormValues({
            ...formValues,
            [name]: { error: false, errorMessage: '' },
          })
        }
        break
    }

    setCurrentStudent({ ...currentStudent, [name]: value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('NOM_ETU', currentStudent?.NOM_ETU)
    formData.append('PRENOM_ETU', currentStudent?.PRENOM_ETU)
    formData.append('NOM_ETU_AR', currentStudent?.NOM_ETU_AR)
    formData.append('PRENOM_ETU_AR', currentStudent?.PRENOM_ETU_AR)
    formData.append('DT_NAIS_ETU', currentStudent?.DT_NAIS_ETU)
    formData.append('LIEU_NAIS_ETU', currentStudent?.LIEU_NAIS_ETU)
    formData.append('LIEU_NAIS_ETU_AR', currentStudent?.LIEU_NAIS_ETU_AR)
    formData.append('WIL_NAIS_ETU', currentStudent?.WIL_NAIS_ETU)
    formData.append('PAYS_NAIS_ETU', currentStudent?.PAYS_NAIS_ETU)
    formData.append('SEXE_ETU', currentStudent?.SEXE_ETU)
    formData.append('NATIONALITE_ETU', currentStudent?.NATIONALITE_ETU)
    formData.append('ADR_ETU', currentStudent?.ADR_ETU)
    formData.append('ADR_ETU_AR', currentStudent?.ADR_ETU_AR)
    formData.append('WIL_ADR_ETU', currentStudent?.WIL_ADR_ETU)
    formData.append('TEL_ETU', currentStudent?.TEL_ETU)
    formData.append('EMAIL_ETU', currentStudent?.EMAIL_ETU)
    formData.append('MATRICULE_BAC', currentStudent?.MATRICULE_BAC)
    formData.append('WIL_BAC_ETU', currentStudent?.WIL_BAC_ETU)
    formData.append('MOYENNE_BAC', currentStudent?.MOYENNE_BAC)
    formData.append('PRESUME', currentStudent?.PRESUME)
    formData.append('ANNEE_PRESUME', currentStudent?.ANNEE_PRESUME)
    formData.append('ANNEE_BAC', currentStudent?.ANNEE_BAC)
    formData.append('ETABLISSEMENT', currentStudent?.ETABLISSEMENT)
    formData.append('FILES', currentStudent?.FILES[0])
    await dispatch(registrationStudent({ formData, lang }))
    setSubmit(true)
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#eeeeee',
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const imageUrlRegex = /\.(jpeg|jpg|gif|png)$/i

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading || wilaya?.isLoading || country?.isLoading || nationality?.isLoading}
        //onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar sx={{ background: '#000000' }} position="static">
          <Toolbar>
            <>
              <CardMedia
                component="img"
                sx={{
                  width: 200,
                  height: 60,
                }}
                image={'/logo_universco.png'}
                alt="Logo"
              />

              <Typography
                sx={{ flexGrow: 1, display: { xs: 'none', lg: 'block', xl: 'block' } }}
                variant="h5"
                component="div"
              >
                {minInstitutionData?.LANGUE_DEFAUT_ETAB === 'Français'
                  ? minInstitutionData?.LIB_ETAB?.length > 90
                    ? minInstitutionData?.LIB_ETAB_C
                    : minInstitutionData?.LIB_ETAB
                  : minInstitutionData?.LIB_ETAB_EN?.length > 90
                  ? minInstitutionData?.LIB_ETAB_EN_C
                  : minInstitutionData?.LIB_ETAB_EN}
              </Typography>
              <Typography
                sx={{ flexGrow: 1, display: { xs: 'block', lg: 'none', xl: 'none' } }}
                variant="h5"
                component="div"
              >
                {minInstitutionData?.LANGUE_DEFAUT_ETAB === 'Français'
                  ? minInstitutionData?.LIB_ETAB_C
                  : minInstitutionData?.LIB_ETAB_EN_C}
              </Typography>

              <Button color="inherit">
                <Link
                  to={window.location.origin.replace('admins-', '')}
                  style={{ textDecoration: 'none', color: 'white' }}
                >
                  {minInstitutionData?.LANGUE_DEFAUT_ETAB === 'Français' ? 'Accueil' : 'Home'}
                </Link>
              </Button>
            </>
          </Toolbar>
        </AppBar>
      </Box>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        sx={{ margin: 1 }}
      >
        <Grid item>
          <CardMedia
            component="img"
            sx={{
              width: parseInt(process.env.REACT_APP_LOGO_WIDTH) || 10,
              height: parseInt(process.env.REACT_APP_LOGO_HEIGHT) || 10,
            }}
            image={'/logo_institution.png'}
            alt="Logo"
          />
        </Grid>
      </Grid>
      <Box
        component="form"
        sx={{
          maxWidth: '90%',
          margin: 'auto',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0,0,0,0.3)',
          backgroundColor: 'white',
          marginTop: 2,
        }}
      >
        <Typography variant="h5" component="div" sx={{ mb: 2 }}>
          {lang === 'Français'
            ? `Formulaire d'inscription (Note aux candidats au concours du second cycle)`
            : `Registration form`}
        </Typography>

        <Typography variant="body1" component="div" sx={{ mb: 2 }}>
          Vous êtes tenus à remplir avec soin le formulaire en ligne en veillant à fournir toutes
          les informations demandées de manière claire et complète. Assurez-vous que votre dossier
          de candidature soit complet (relevés de notes, attestations d'inscription de l'année en
          cours, etc.). Soyez attentif aux dates limites de dépôt des candidatures.
        </Typography>
        <Grid container spacing={2} mb={2}>
          <Grid item md={4} xs={12}>
            <TextField
              label={lang === 'Français' ? 'Nom' : 'Last name'}
              name="NOM_ETU"
              fullWidth
              value={currentStudent?.NOM_ETU || ''}
              onChange={handleInputChange}
              variant="standard"
              error={formValues.NOM_ETU.error}
              helperText={formValues.NOM_ETU.error && formValues.NOM_ETU.errorMessage}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item md={4} xs={12}></Grid>
          <Grid item md={4} xs={12}>
            <TextField
              sx={{
                '& label': {
                  left: 'unset',
                  right: '0',
                  transformOrigin: 'right',
                },
                '& legend': {
                  textAlign: 'right',
                },
                input: { right: 0, textAlign: 'right' },
              }}
              fullWidth
              id="standard-basic"
              label="اللقب"
              onChange={handleInputChange}
              value={currentStudent?.NOM_ETU_AR || ''}
              variant="standard"
              name="NOM_ETU_AR"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item md={4} xs={12}>
            <TextField
              label={lang === 'Français' ? 'Prénom' : 'First name'}
              name="PRENOM_ETU"
              fullWidth
              value={currentStudent?.PRENOM_ETU || ''}
              onChange={handleInputChange}
              variant="standard"
              error={formValues.PRENOM_ETU.error}
              helperText={formValues.PRENOM_ETU.error && formValues.PRENOM_ETU.errorMessage}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item md={4} xs={12}></Grid>
          <Grid item md={4} xs={12}>
            <TextField
              sx={{
                '& label': {
                  left: 'unset',
                  right: '0',
                  transformOrigin: 'right',
                },
                '& legend': {
                  textAlign: 'right',
                },
                input: { right: 0, textAlign: 'right' },
              }}
              fullWidth
              id="standard-basic"
              label="الإسم"
              onChange={handleInputChange}
              value={currentStudent?.PRENOM_ETU_AR || ''}
              variant="standard"
              name="PRENOM_ETU_AR"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item md={4} xs={12}>
            <FormControl sx={{ width: 1 }} variant="standard">
              <InputLabel id="demo-simple-select-standard-label">
                {lang === 'Français' ? 'Sexe' : 'Sex'}
              </InputLabel>
              <Select
                fullWidth={true}
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={currentStudent?.SEXE_ETU || 'Masculin'}
                label={lang === 'Français' ? 'Sexe' : 'Sex'}
                onChange={handleInputChange}
                variant="standard"
                name="SEXE_ETU"
              >
                {lang === 'Français' ? (
                  <MenuItem key="Masculin" value="Masculin">
                    Masculin
                  </MenuItem>
                ) : (
                  <MenuItem key="Masculin" value="Masculin">
                    Male
                  </MenuItem>
                )}
                {lang === 'Français' ? (
                  <MenuItem key="Féminin" value="Féminin">
                    Féminin
                  </MenuItem>
                ) : (
                  <MenuItem key="Féminin" value="Féminin">
                    Female
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item md={4} xs={12}>
            <DatePicker
              inputFormat="DD/MM/YYYY"
              //format="DD/MM/YYYY"
              label={lang === 'Français' ? 'Date de naissance' : 'Date of birth'}
              value={dayjs(currentStudent?.DT_NAIS_ETU) || ''}
              slotProps={{
                textField: { variant: 'standard', fullWidth: true },
              }}
              name="DT_NAIS_ETU"
              onChange={(date) => {
                const d = new Date(date)

                setCurrentStudent({
                  ...currentStudent,
                  DT_NAIS_ETU: d,
                })
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              label={lang === 'Français' ? 'Lieu de naissance' : 'Place of birth'}
              name="LIEU_NAIS_ETU"
              fullWidth
              value={currentStudent?.LIEU_NAIS_ETU || ''}
              onChange={handleInputChange}
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              sx={{
                '& label': {
                  left: 'unset',
                  right: '0',
                  transformOrigin: 'right',
                },
                '& legend': {
                  textAlign: 'right',
                },
                input: { right: 0, textAlign: 'right' },
              }}
              fullWidth
              id="standard-basic"
              label="مكان الإزدياد"
              onChange={handleInputChange}
              value={currentStudent?.LIEU_NAIS_ETU_AR || ''}
              variant="standard"
              name="LIEU_NAIS_ETU_AR"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item md={4} xs={12}>
            <FormControl sx={{ width: 1 }} variant="standard">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                onChange={(e, value) => {
                  if (value) {
                    const id = value?.ID_WIL
                    const nom = wilaya?.wilayaData
                      ?.filter((n) => n.ID_WIL.toString() === (value?.ID_WIL).toString())
                      .map((obj) => {
                        return {
                          LIB_WIL: obj.LIB_WIL,
                          LIB_WIL_EN: obj.LIB_WIL_EN,
                        }
                      })
                    setInitWilayaValue({
                      ID_WIL: id,
                      LIB_WIL: nom[0].LIB_WIL,
                      LIB_WIL_EN: nom[0].LIB_WIL_EN,
                    }),
                      setCurrentStudent({
                        ...currentStudent,
                        WIL_NAIS_ETU: value?.ID_WIL,
                      })
                  } else {
                    setInitWilayaValue({
                      ID_WIL: '',
                      LIB_WIL: '',
                      LIB_WIL_EN: '',
                    }),
                      setCurrentStudent({ ...currentStudent, WIL_NAIS_ETU: '' })
                  }
                }}
                getOptionLabel={(option) =>
                  lang === 'Français' ? option?.LIB_WIL : option?.LIB_WIL_EN
                }
                value={initWilayaValue}
                options={listWilaya}
                isOptionEqualToValue={(option, value) => option?.value === value?.value}
                name="WIL_NAIS_ETU"
                renderOption={(props, option) => (
                  <Box component="li" {...props} key={option?.ID_WIL}>
                    {lang === 'Français' ? option?.LIB_WIL : option?.LIB_WIL_EN}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={lang === 'Français' ? 'Wilaya de naissance' : 'Wilaya of birth'}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item md={4} xs={12}>
            <FormControl sx={{ width: 1 }} variant="standard">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                onChange={(e, value) => {
                  if (value) {
                    const id = value?.ID_PAYS
                    const nom = country?.countryData
                      ?.filter((n) => n.ID_PAYS.toString() === (value?.ID_PAYS).toString())
                      .map((obj) => {
                        return {
                          LIB_PAYS: obj.LIB_PAYS,
                          LIB_PAYS_EN: obj.LIB_PAYS_EN,
                        }
                      })
                    setInitCountryValue({
                      ID_PAYS: id,
                      LIB_PAYS: nom[0].LIB_PAYS,
                      LIB_PAYS_EN: nom[0].LIB_PAYS_EN,
                    }),
                      setCurrentStudent({
                        ...currentStudent,
                        PAYS_NAIS_ETU: value?.ID_PAYS,
                      })
                  } else {
                    setInitCountryValue({
                      ID_PAYS: '',
                      LIB_PAYS: '',
                      LIB_PAYS_EN: '',
                    }),
                      setCurrentStudent({ ...currentStudent, PAYS_NAIS_ETU: '' })
                  }
                }}
                getOptionLabel={(option) =>
                  lang === 'Français' ? option?.LIB_PAYS : option?.LIB_PAYS_EN
                }
                value={initCountryValue}
                options={listCountry}
                isOptionEqualToValue={(option, value) => option?.value === value?.value}
                name="PAYS_NAIS_ETU"
                renderOption={(props, option) => (
                  <Box component="li" {...props} key={option?.ID_PAYS}>
                    {lang === 'Français' ? option?.LIB_PAYS : option?.LIB_PAYS_EN}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={lang === 'Français' ? 'Pays de naissance' : 'Country of birth'}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item md={4} xs={12}>
            <FormControl sx={{ width: 1 }} variant="standard">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                onChange={(e, value) => {
                  if (value) {
                    const id = value?.ID_NAT
                    const nom = nationality?.nationalityData
                      ?.filter((n) => n.ID_NAT.toString() === (value?.ID_NAT).toString())
                      .map((obj) => {
                        return {
                          LIB_NAT: obj.LIB_NAT,
                          LIB_NAT_EN: obj.LIB_NAT_EN,
                        }
                      })
                    setInitNationalityValue({
                      ID_NAT: id,
                      LIB_NAT: nom[0].LIB_NAT,
                      LIB_NAT_EN: nom[0].LIB_NAT_EN,
                    }),
                      setCurrentStudent({
                        ...currentStudent,
                        NATIONALITE_ETU: value?.ID_NAT,
                      })
                  } else {
                    setInitNationalityValue({
                      ID_NAT: '',
                      LIB_NAT: '',
                      LIB_NAT_EN: '',
                    }),
                      setCurrentStudent({
                        ...currentStudent,
                        NATIONALITE_ETU: '',
                      })
                  }
                }}
                getOptionLabel={(option) =>
                  lang === 'Français' ? option?.LIB_NAT : option?.LIB_NAT_EN
                }
                value={initNationalityValue}
                options={listNationality}
                isOptionEqualToValue={(option, value) => option?.value === value?.value}
                name="NATIONALITE_ETU"
                renderOption={(props, option) => (
                  <Box component="li" {...props} key={option?.ID_NAT}>
                    {lang === 'Français' ? option?.LIB_NAT : option?.LIB_NAT_EN}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={lang === 'Français' ? 'Nationalité' : 'Nationality'}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              id="standard-basic"
              label={lang === 'Français' ? 'Adresse' : 'Address'}
              onChange={handleInputChange}
              value={currentStudent?.ADR_ETU || ''}
              variant="standard"
              name="ADR_ETU"
              multiline
              rows={2}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              sx={{
                '& label': {
                  left: 'unset',
                  right: '0',
                  transformOrigin: 'right',
                },
                '& legend': {
                  textAlign: 'right',
                },
              }}
              inputProps={{ style: { textAlign: 'right' } }}
              fullWidth
              id="standard-basic"
              label="العنوان"
              onChange={handleInputChange}
              value={currentStudent?.ADR_ETU_AR || ''}
              variant="standard"
              name="ADR_ETU_AR"
              multiline
              rows={2}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item md={4} xs={12}>
            <FormControl sx={{ width: 1 }} variant="standard">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                onChange={(e, value) => {
                  if (value) {
                    const id = value?.ID_WIL
                    const nom = wilaya?.wilayaData
                      ?.filter((n) => n.ID_WIL.toString() === (value?.ID_WIL).toString())
                      .map((obj) => {
                        return {
                          LIB_WIL: obj.LIB_WIL,
                          LIB_WIL_EN: obj.LIB_WIL_EN,
                        }
                      })
                    setInitWilayaResidenceValue({
                      ID_WIL: id,
                      LIB_WIL: nom[0].LIB_WIL,
                      LIB_WIL_EN: nom[0].LIB_WIL_EN,
                    }),
                      setCurrentStudent({
                        ...currentStudent,
                        WIL_ADR_ETU: value?.ID_WIL,
                      })
                  } else {
                    setInitWilayaResidenceValue({
                      ID_WIL: '',
                      LIB_WIL: '',
                      LIB_WIL_EN: '',
                    }),
                      setCurrentStudent({ ...currentStudent, WIL_ADR_ETU: '' })
                  }
                }}
                getOptionLabel={(option) =>
                  lang === 'Français' ? option?.LIB_WIL : option?.LIB_WIL_EN
                }
                value={initWilayaResidenceValue}
                options={listWilaya}
                isOptionEqualToValue={(option, value) => option?.value === value?.value}
                name="WIL_ADR_ETU"
                renderOption={(props, option) => (
                  <Box component="li" {...props} key={option?.ID_WIL}>
                    {lang === 'Français' ? option?.LIB_WIL : option?.LIB_WIL_EN}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={lang === 'Français' ? 'Wilaya de résidence' : 'Wilaya of residence'}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item md={3} xs={12}>
            <TextField
              label={lang === 'Français' ? 'Téléphone' : 'Phone'}
              name="TEL_ETU"
              fullWidth
              value={currentStudent?.TEL_ETU}
              onChange={handleInputChange}
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item md={3} xs={12}>
            <TextField
              label={lang === 'Français' ? 'Mail personnel' : 'Personal email'}
              name="EMAIL_ETU"
              fullWidth
              value={currentStudent?.EMAIL_ETU}
              onChange={handleInputChange}
              variant="standard"
              InputLabelProps={{ shrink: true }}
              error={formValues.EMAIL_ETU.error}
              helperText={formValues.EMAIL_ETU.error && formValues.EMAIL_ETU.errorMessage}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item md={2} xs={12}>
            <TextField
              label={lang === 'Français' ? 'Matricule du BAC' : 'BAC Identification number'}
              name="MATRICULE_BAC"
              fullWidth
              value={currentStudent?.MATRICULE_BAC || ''}
              onChange={handleInputChange}
              variant="standard"
              error={formValues.MATRICULE_BAC.error}
              helperText={formValues.MATRICULE_BAC.error && formValues.MATRICULE_BAC.errorMessage}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <TextField
              label={lang === 'Français' ? 'Moyenne du BAC' : 'BAC average'}
              name="MOYENNE_BAC"
              type="number"
              fullWidth
              value={currentStudent?.MOYENNE_BAC || ''}
              onChange={handleInputChange}
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <TextField
              label={lang === 'Français' ? 'Année du BAC' : 'Year of the BAC'}
              name="ANNEE_BAC"
              fullWidth
              type="number"
              value={currentStudent?.ANNEE_BAC || ''}
              onChange={handleInputChange}
              variant="standard"
              error={formValues.ANNEE_BAC.error}
              helperText={formValues.ANNEE_BAC.error && formValues.ANNEE_BAC.errorMessage}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl sx={{ width: 1 }} variant="standard">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                onChange={(e, value) => {
                  if (value) {
                    const id = value?.ID_WIL
                    const nom = wilaya?.wilayaData
                      ?.filter((n) => n.ID_WIL.toString() === (value?.ID_WIL).toString())
                      .map((obj) => {
                        return {
                          LIB_WIL: obj.LIB_WIL,
                          LIB_WIL_EN: obj.LIB_WIL_EN,
                        }
                      })
                    setInitWilayaBacValue({
                      ID_WIL: id,
                      LIB_WIL: nom[0].LIB_WIL,
                      LIB_WIL_EN: nom[0].LIB_WIL_EN,
                    }),
                      setCurrentStudent({
                        ...currentStudent,
                        WIL_BAC_ETU: value?.ID_WIL,
                      })
                  } else {
                    setInitWilayaBacValue({
                      ID_WIL: '',
                      LIB_WIL: '',
                      LIB_WIL_EN: '',
                    }),
                      setCurrentStudent({ ...currentStudent, WIL_BAC_ETU: '' })
                  }
                }}
                getOptionLabel={(option) =>
                  lang === 'Français' ? option?.LIB_WIL : option?.LIB_WIL_EN
                }
                value={initWilayaBacValue}
                options={listWilaya}
                isOptionEqualToValue={(option, value) => option?.value === value?.value}
                name="WIL_BAC_ETU"
                renderOption={(props, option) => (
                  <Box component="li" {...props} key={option?.ID_WIL}>
                    {lang === 'Français' ? option?.LIB_WIL : option?.LIB_WIL_EN}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={lang === 'Français' ? 'Wilaya du BAC' : 'Wilaya of the BAC'}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item md={6} xs={12}>
            <TextField
              label={lang === 'Français' ? `Etablissement d'origine` : 'Establishment of origin'}
              name="ETABLISSEMENT"
              fullWidth
              value={currentStudent?.ETABLISSEMENT || ''}
              onChange={handleInputChange}
              variant="standard"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} mb={2}>
          <Grid item md={12} xs={12}>
            <Typography variant="h6" gutterBottom>
              Relevé de notes du BAC, 1re année, 2e année et certificat d'inscription en cours (un
              seul fichier PDF)
            </Typography>
            <div className="container">
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <Typography>
                  Glissez/déplacez ou sélectionnez votre fichier au format PDF
                </Typography>
              </div>
            </div>
            <aside>
              {formValues?.FILES.error ? (
                <Typography variant="caption" display="block" gutterBottom sx={{ color: 'red' }}>
                  {formValues?.FILES.errorMessage}
                </Typography>
              ) : null}
              <Typography variant="subtitle2" gutterBottom>
                Accepted files
              </Typography>
              <ul>{acceptedFileItems}</ul>
              <Typography variant="subtitle2" gutterBottom>
                Rejected files
              </Typography>
              <ul>{fileRejectionItems}</ul>
            </aside>
          </Grid>
        </Grid>
        {submit === true ? (
          <Alert severity="success">
            Votre inscription a été effectuée avec succès. Veuillez consulter votre email.
          </Alert>
        ) : null}
        <Grid container spacing={2} mb={2}>
          <Grid item md={12} xs={12}>
            <Button
              variant="contained"
              size="small"
              onClick={handleSubmit}
              disabled={
                formValues?.NOM_ETU.error ||
                formValues?.PRENOM_ETU.error ||
                formValues?.MATRICULE_BAC.error ||
                formValues?.EMAIL_ETU.error ||
                formValues?.FILES.error
              }
            >
              {lang === 'Français' ? 'Envoyer' : 'Send'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

const blue = {
  200: '#A5D8FF',
  400: '#3399FF',
}

const grey = {
  50: '#F3F6F9',
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#1A2027',
}

const CustomTablePagination = styled(TablePagination)(
  ({ theme }) => `
  & .${classes.spacer} {
    display: none;
    
  }

  & .${classes.toolbar}  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  & .${classes.selectLabel} {
    margin: 0;
    font-size:14px;
  }

  & .${classes.select}{
    padding: 2px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    border-radius: 50px;
    background-color: transparent;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    font-size:14px;

    &:hover {
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
      font-size:14px;
    }

    &:focus {
      outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
      font-size:14px;
    }
  }

  & .${classes.displayedRows} {
    margin: 0;
    font-size:14px;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }

  & .${classes.actions} {
    padding: 2px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    border-radius: 50px;
    text-align: right;
  }

  & .${classes.actions} > button {
    margin: 0 8px;
    border: transparent;
    border-radius: 4px;
    background-color: transparent;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};

    &:hover {
      background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    }

    &:focus {
      outline: 1px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
    }

    &:disabled {
      opacity: 0.3;
    }
  }
  `,
)

export default Registration
