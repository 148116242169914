import { createTheme } from '@mui/material/styles'
import { ThemeProvider } from '@mui/material/styles'
import React, { Component, Suspense, useEffect } from 'react'
import { BrowserRouter, HashRouter, Navigate, Route, Routes } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/fr'
import Registration from './pages/Registration'
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const theme = createTheme({
  typography: {
    fontFamily: `system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', 'Noto Sans',
    'Liberation Sans', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji'`,
  },
})

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./pages/forms/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

function App() {
  const { user } = useSelector((state) => state.auth)

  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
          <ThemeProvider theme={theme}>
            <Routes>
              <Route
                exact
                path="/login"
                name="Login Page"
                element={!user ? <Login /> : <Navigate to="/" />}
              />
              <Route
                exact
                path="/registration"
                name="Registration Page"
                element={<Registration />}
              />
              <Route
                exact
                path="/register"
                name="Register Page"
                element={!user ? <Register /> : <Navigate to="/" />}
              />
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              <Route path="*" name="Home" element={!user ? <Login /> : <DefaultLayout />} />
            </Routes>
          </ThemeProvider>
        </LocalizationProvider>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
