import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import request from 'src/utils/request'
import axios from 'axios'

const initialState = {
  isLoading: false,
  isLoadingGrades: false,
  isLoadingCursus: false,
  isLoadingImport: false,
  isLoadingAllStudents: false,
  isSuccess: false,
  isSuccessGrades: false,
  isSuccessCursus: false,
  isSuccessImport: false,
  isSuccessAllStudents: false,
  studentData: [],
  registrationData: [],
  allStudentData: [],
  currentStudentData: [],
  cursusByStudentData: [],
  gradesByStudentData: [],
  gradesByCourseData: [],
  error: '',
  errorImport: '',
}

export const getStudentInfo = createAsyncThunk(
  'student/getStudentInfo',
  async ({ studentID, specialiteID }, thunkAPI) => {
    try {
      const res = await axios.get(`/api/student/studentInfo`, {
        params: { matricule: studentID, id_spe: specialiteID },
      })
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const getStudentByID = createAsyncThunk(
  'student/getStudentByID',
  async ({ studentID, specialiteID }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res = await request(token).get(`/api/student/student`, {
        params: { matricule: studentID, id_spe: specialiteID },
      })
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const getRegistrationForm = createAsyncThunk(
  'student/getRegistrationForm',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res = await request(token).get(`/api/student/freeStudent`)
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const putRegistrationForm = createAsyncThunk(
  'student/putRegistrationForm',
  async ({ currentRegistration, value, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).put(`/api/student/freeStudent`, {
        data: currentRegistration,
        value,
      })
      const message =
        lang === 'Français'
          ? `Dossier d'inscription modifié avec succès`
          : 'Registration form updated successfully'
      toast.success(message)
      //console.log(currentGrades)
      /*const res = await request.get(`/api/student/gradesByStudent`, {
        params: { matricule: currentGrades[0].MATRICULE, id_spe: currentGrades[0].ID_SPE },
      })*/
      const data = await res1.data
      return data
    } catch (err) {
      console.log(err)
      const message =
        lang === 'Français'
          ? `Dossier d'inscription n'a pas pu être modifié`
          : 'Registration form could not be modified'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const getAllStudents = createAsyncThunk('student/getAllStudents', async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    const res = await request(token).get(`/api/student/allStudents`)
    const data = await res.data
    return data
  } catch (err) {
    toast.error(err.response.data)
    return thunkAPI.rejectWithValue(err.response.data)
  }
})

export const calculateAverage = createAsyncThunk(
  'student/calculateAverage',
  async ({ studentID, specialiteID, dette, redouble, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res = await request(token).get(`/api/student/calculateAverage`, {
        params: { matricule: studentID, id_spe: specialiteID, dette, redouble },
      })
      const data = await res.data
      const message =
        lang === 'Français'
          ? 'Le calcul a été effectué avec succès'
          : 'The calculation was completed successfully'
      toast.success(message)
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const calculateRanking = createAsyncThunk(
  'student/calculateRanking',
  async ({ studentID, specialiteID, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res = await request(token).get(`/api/student/calculateRanking`, {
        params: { matricule: studentID, id_spe: specialiteID },
      })
      const data = await res.data
      const message =
        lang === 'Français'
          ? 'Le calcul a été effectué avec succès'
          : 'The calculation was completed successfully'
      toast.success(message)
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const getCursusByStudent = createAsyncThunk(
  'student/getCursusByStudent',
  async ({ studentID }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res = await request(token).get(`/api/student/cursusByStudent`, {
        params: { matricule: studentID },
      })
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const getGradesByStudent = createAsyncThunk(
  'student/getGradesByStudent',
  async ({ studentID, specialityID, courseID, semesterID }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res = await request(token).get(`/api/student/gradesByStudent`, {
        params: {
          matricule: studentID,
          id_spe: specialityID,
          id_mat: courseID,
          id_sem: semesterID,
        },
      })
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const getGradesByCourse = createAsyncThunk(
  'student/getGradesByCourse',
  async ({ studentID, specialityID, courseID, semesterID }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res = await request(token).get(`/api/student/gradesByStudent`, {
        params: {
          matricule: studentID,
          id_spe: specialityID,
          id_mat: courseID,
          id_sem: semesterID,
        },
      })
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const getStudentList = createAsyncThunk(
  'student/getStudentList',
  async (selectedYear, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res = await request(token).get(`/api/student/student`, {
        params: { annee_universitaire: selectedYear },
      })
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const getStudentListAsync = createAsyncThunk(
  'student/getStudentListAsync',
  async (selectedYear, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      //const res =
      return request(token)
        .get(`/api/student/student`, {
          params: { annee_universitaire: selectedYear },
        })
        .then((response) => {
          console.log(response)
          return response.data
        })

      //const data = res.data
      //console.log(data)
      //return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const getStudentsBySpeciality = createAsyncThunk(
  'student/getStudentsBySpeciality',
  async ({ specialityID }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res = await request(token).get(`/api/student/studentsBySpeciality`, {
        params: { id_spe: specialityID },
      })
      const data = await res.data
      return data
    } catch (err) {
      toast.error(err.response.data)
      return thunkAPI.rejectWithValue(err.response.data)
    }
  },
)

export const updateStudent = createAsyncThunk(
  'student/updateStudent',
  async ({ formData, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).put(`/api/student/student`, formData)
      const message =
        lang === 'Français'
          ? res1.data.rowUpdated + ' étudiant(s) modifié(s) avec succès'
          : res1.data.rowUpdated + ' student(s) updated successfully'
      toast.success(message)
      const data = await res1.data
      return data
    } catch (err) {
      console.log(err)
      const message =
        lang === 'Français'
          ? err.response.data.rowUpdated + ' étudiant(s) mis à jour avec succès'
          : err.response.data.rowUpdated + ' student(s) updated successfully'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const updateGradesByStudent = createAsyncThunk(
  'student/updateGradesByStudent',
  async ({ currentGrades, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).put(`/api/student/gradesByStudent`, currentGrades)
      const message =
        lang === 'Français'
          ? res1.data.rowUpdated + ' note(s) modifiée(s) avec succès'
          : res1.data.rowUpdated + ' grades(s) updated successfully'
      toast.success(message)
      //console.log(currentGrades)
      /*const res = await request.get(`/api/student/gradesByStudent`, {
        params: { matricule: currentGrades[0].MATRICULE, id_spe: currentGrades[0].ID_SPE },
      })*/
      const data = await res1.data
      return data
    } catch (err) {
      console.log(err)
      const message =
        lang === 'Français'
          ? err.response.data.rowUpdated +
            '/' +
            (err.response.data.rowUpdated + err.response.data.errorCount).toString() +
            ' notes(s) mise(s) à jour avec succès'
          : err.response.data.rowUpdated +
            '/' +
            (err.response.data.rowUpdated + err.response.data.errorCount).toString() +
            ' grade(s) updated successfully'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const subscriptionStudent = createAsyncThunk(
  'student/subscriptionStudent',
  async ({ currentSubscription, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).post(
        `/api/student/subscriptionStudent`,
        currentSubscription /*{
        matricule: currentSubscription?.MATRICULE,
        id_spe: currentSubscription?.ID_SPE,
      }*/,
      )
      const dt = await res1.data
      const message =
        lang === 'Français'
          ? dt.rowInserted + ' étudiant(s) inscrit(e) avec succès'
          : dt.rowInserted + ' student(s) subscribed successfully'
      toast.success(message)

      return dt
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ' +
            err.response.data.message.errorNum +
            " ! L'étudiant(e) n'a pas pu être inscrit(e)"
          : 'Error ' + err.response.data.message.errorNum + ' ! The student could not be subscribed'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const updateSubscriptionStudent = createAsyncThunk(
  'student/updateSubscriptionStudent',
  async ({ currentCursus, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).put(`/api/student/subscriptionStudent`, {
        currentCursus,
      })
      const message =
        lang === 'Français'
          ? 'Modification(s) effectuée(s) avec succès'
          : 'Change(s) completed successfully'
      toast.success(message)
      const data = await res1.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ' +
            err.response.data.message.errorNum +
            " ! L'étudiant(e) n'a pas pu être modifié(e)"
          : 'Error ' + err.response.data.message.errorNum + ' ! The student could not be edited'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const resetGrades = createAsyncThunk(
  'student/resetGrades',
  async ({ currentCursus, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).put(`/api/student/resetGrades`, {
        currentCursus,
      })
      const message =
        lang === 'Français'
          ? 'Initialisation(s) effectuée(s) avec succès'
          : 'Reset completed successfully'
      toast.success(message)
      const data = await res1.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ' +
            err.response.data.message.errorNum +
            " ! La réinitialisation n'a pas pu être effectuée"
          : 'Error ' + err.response.data.message.errorNum + ' ! The reset could not be completed'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const deleteCourseFromTranscript = createAsyncThunk(
  'student/deleteCourseFromTranscript',
  async ({ childrenProps, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).put(`/api/student/deleteCourseFromTranscript`, {
        data: childrenProps.selected,
      })
      const message =
        lang === 'Français'
          ? `L'opération a été effectuée avec succès`
          : 'The operation was completed successfully'
      toast.success(message)
      const data = await res1.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ' +
            err.response.data.message.errorNum +
            " ! L'opération n'a pas pu être effectuée"
          : 'Error ' +
            err.response.data.message.errorNum +
            ' ! The operation could not be completed'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const transcriptMoveUnit = createAsyncThunk(
  'student/transcriptMoveUnit',
  async ({ childrenProps, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).put(`/api/student/transcriptMoveUnit`, {
        data: childrenProps.selected[0],
      })
      const message =
        lang === 'Français'
          ? `L'opération a été effectuée avec succès`
          : 'The operation was completed successfully'
      toast.success(message)
      const data = await res1.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ' +
            err.response.data.message.errorNum +
            " ! L'opération n'a pas pu être effectuée"
          : 'Error ' +
            err.response.data.message.errorNum +
            ' ! The operation could not be completed'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const transcriptReorderUnits = createAsyncThunk(
  'student/transcriptReorderUnits',
  async ({ childrenProps, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).put(`/api/student/transcriptReorderUnits`, {
        data: childrenProps.selected[0],
      })
      const message =
        lang === 'Français'
          ? `L'opération a été effectuée avec succès`
          : 'The operation was completed successfully'
      toast.success(message)
      const data = await res1.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ' +
            err.response.data.message.errorNum +
            " ! L'opération n'a pas pu être effectuée"
          : 'Error ' +
            err.response.data.message.errorNum +
            ' ! The operation could not be completed'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const transcriptReorderCourses = createAsyncThunk(
  'student/transcriptReorderCourses',
  async ({ childrenProps, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).put(`/api/student/transcriptReorderCourses`, {
        data: childrenProps.selected[0],
      })
      const message =
        lang === 'Français'
          ? `L'opération a été effectuée avec succès`
          : 'The operation was completed successfully'
      toast.success(message)
      const data = await res1.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ' +
            err.response.data.message.errorNum +
            " ! L'opération n'a pas pu être effectuée"
          : 'Error ' +
            err.response.data.message.errorNum +
            ' ! The operation could not be completed'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const insertStudent = createAsyncThunk(
  'student/insertStudent',
  async ({ formData, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).post(`/api/student/student`, formData)
      const message =
        lang === 'Français' ? 'Etudiant(e) ajouté(e) avec succès' : 'Student added successfully'
      toast.success(message)
      const data = await res1.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ' +
            err.response.data.message.errorNum +
            " ! L'étudiant(e) n'a pas pu être ajouté(e)"
          : 'Error ' + err.response.data.message.errorNum + ' ! The student could not be added'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const registrationStudent = createAsyncThunk(
  'student/registrationStudent',
  async ({ formData, lang }) => {
    try {
      const res = await axios.post(`/api/student/freeStudent`, formData, {
        baseURL: `${process.env.REACT_APP_SERVER_DOMAIN}`,
        headers: {
          'Access-Control-Allow-Origin': `${process.env.REACT_APP_SERVER_DOMAIN}`,
          //'Content-Type': 'application/json',
          'Content-Type': 'multipart/form-data',
          //Authorization: `Bearer ${token}`,
        },
      })
      const message =
        lang === 'Français' ? 'Etudiant(e) ajouté(e) avec succès' : 'Student added successfully'
      toast.success(message)
      const data = await res.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ' +
            err.response.data.message.errorNum +
            " ! L'étudiant(e) n'a pas pu être ajouté(e)"
          : 'Error ' + err.response.data.message.errorNum + ' ! The student could not be added'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const unsubscribeStudent = createAsyncThunk(
  'student/unsubscribeStudent',
  async ({ childrenProps, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).delete(`/api/student/subscriptionStudent`, {
        data: childrenProps.selected,
      })
      const dt = await res1.data
      toast.success(
        lang === 'Français'
          ? dt?.rowDeleted + ' étudiant(s) désinscrit(s) avec succès'
          : dt?.rowDeleted + ' student(s) unsubscribed successfully',
      )
      const data = await res1.data
      return childrenProps
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ! ' +
            err.response.data.errorCount +
            ' sur ' +
            childrenProps.selected.length +
            " étudiant(s) n'ont pas pu être désinscrit(s)"
          : 'Error ! ' +
            err.response.data.message.errorNum +
            ' of ' +
            childrenProps.selected.length +
            ' student(s) could not be unsubscribed'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const deleteStudent = createAsyncThunk(
  'student/deleteStudent',
  async ({ childrenProps, lang }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).delete(`/api/student/student`, {
        data: childrenProps.selected,
      })
      const dt = await res1.data
      toast.success(
        lang === 'Français'
          ? dt?.rowDeleted + ' étudiant(s) supprimé(s) avec succès'
          : dt?.rowDeleted + ' student(s) deleted successfully',
      )
      const data = await res1.data
      return data
    } catch (err) {
      const message =
        lang === 'Français'
          ? 'Erreur ! ' +
            err.response.data.errorCount +
            ' sur ' +
            childrenProps.selected.length +
            " étudiant(s) n'ont pas pu être supprimé(s)"
          : 'Error ! ' +
            err.response.data.message.errorNum +
            ' of ' +
            childrenProps.selected.length +
            ' student(s) could not be deleted'
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const importStudent = createAsyncThunk(
  'student/importStudent',
  async ({ studentData, lang, importMode, selectedYear }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      const res1 = await request(token).put(`/api/student/studentImport`, {
        data: studentData,
        importMode,
        selectedYear,
      })
      const dt = await res1.data
      importMode === 1 || importMode === 2
        ? toast.success(
            lang === 'Français'
              ? dt.rowUpdated + ' étudiant(s) mis à jour avec succès'
              : dt.rowUpdated + ' student(s) updated successfully',
          )
        : null
      importMode === 1 || importMode === 3
        ? toast.success(
            lang === 'Français'
              ? dt.rowInserted + ` étudiant(s) inséré(s) avec succès`
              : dt.rowInserted + ' student(s) added successfully',
          )
        : null
      const data = await res1.data
      return data
    } catch (err) {
      console.log(err)
      var message
      importMode === 1 || importMode === 2
        ? (message =
            lang === 'Français'
              ? err.response.data.rowUpdated + ' étudiant(s) mis à jour avec succès'
              : err.response.data.rowUpdated + ' student(s) updated successfully')
        : null
      importMode === 1 || importMode === 3
        ? (message =
            lang === 'Français'
              ? err.response.data.rowInserted + ` étudiant(s) inséré(s) avec succès`
              : err.response.data.rowInserted + ' student(s) added successfully')
        : null
      toast.error(message)
      return thunkAPI.rejectWithValue(message)
    }
  },
)

export const studentSlice = createSlice({
  name: 'student',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(registrationStudent.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(registrationStudent.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          error: '',
        }
      })
      .addCase(registrationStudent.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(getRegistrationForm.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(getRegistrationForm.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          registrationData: action.payload,
          error: '',
        }
      })
      .addCase(getRegistrationForm.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(getStudentsBySpeciality.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(getStudentsBySpeciality.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          studentData: action.payload,
          error: '',
        }
      })
      .addCase(getStudentsBySpeciality.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })

      .addCase(getStudentList.pending, (state) => {
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(getStudentList.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          studentData: action.payload,
          error: '',
        }
      })
      .addCase(getStudentList.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(getStudentListAsync.pending, (state) => {
        console.log('pending')
        return {
          ...state,
          isLoading: true,
        }
      })
      .addCase(getStudentListAsync.fulfilled, (state, action) => {
        console.log('fullfilled')
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          studentData: action.payload,
          error: '',
        }
      })
      .addCase(getStudentListAsync.rejected, (state, action) => {
        console.log('rejected')
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(getStudentByID.pending, (state) => {
        return {
          ...state,
        }
      })
      .addCase(getStudentByID.fulfilled, (state, action) => {
        return {
          ...state,
          isSuccess: true,
          currentStudentData: action.payload,
          error: '',
        }
      })
      .addCase(getStudentByID.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.error.message,
        }
      })
      .addCase(getCursusByStudent.pending, (state) => {
        return {
          ...state,
          isLoadingCursus: true,
        }
      })
      .addCase(getCursusByStudent.fulfilled, (state, action) => {
        return {
          ...state,
          isLoadingCursus: false,
          isSuccessCursus: true,
          cursusByStudentData: action.payload,
          error: '',
        }
      })
      .addCase(getCursusByStudent.rejected, (state, action) => {
        return {
          ...state,
          isLoadingCursus: false,
          isSuccessCursus: false,
          error: action.error.message,
        }
      })
      .addCase(getGradesByStudent.pending, (state) => {
        return {
          ...state,
          isLoadingGrades: true,
        }
      })
      .addCase(getGradesByStudent.fulfilled, (state, action) => {
        return {
          ...state,
          isLoadingGrades: false,
          //isSuccessGrades: true,
          gradesByStudentData: action.payload,
          error: '',
        }
      })
      .addCase(getGradesByStudent.rejected, (state, action) => {
        return {
          ...state,
          isLoadingGrades: false,
          //isSuccessGrades: false,
          error: action.error.message,
        }
      })
      .addCase(getGradesByCourse.pending, (state) => {
        return {
          ...state,
          //isLoadingGrades: true,
        }
      })
      .addCase(getGradesByCourse.fulfilled, (state, action) => {
        return {
          ...state,
          //isLoadingGrades: false,
          //isSuccessGrades: true,
          gradesByCourseData: action.payload,
          error: '',
        }
      })
      .addCase(getGradesByCourse.rejected, (state, action) => {
        return {
          ...state,
          //isLoadingGrades: false,
          //isSuccessGrades: false,
          error: action.error.message,
        }
      })

      .addCase(importStudent.pending, (state, action) => {
        return {
          ...state,
          isLoadingImport: true,
        }
      })
      .addCase(importStudent.fulfilled, (state, action) => {
        return {
          ...state,
          isLoadingImport: false,
          isSuccessImport: true,
          //teacherData: action.payload,
          //errorImport: action.payload,
        }
      })
      .addCase(importStudent.rejected, (state, action) => {
        return {
          ...state,
          isLoadingImport: false,
          isSuccessImport: false,
          errorImport: action.payload,
        }
      })

      .addCase(getAllStudents.pending, (state) => {
        return {
          ...state,
          isLoadingAllStudents: true,
        }
      })
      .addCase(getAllStudents.fulfilled, (state, action) => {
        return {
          ...state,
          isLoadingAllStudents: false,
          isSuccessAllStudents: true,
          allStudentData: action.payload,
          error: '',
        }
      })
      .addCase(getAllStudents.rejected, (state, action) => {
        return {
          ...state,
          isLoadingAllStudents: false,
          isSuccessAllStudents: false,
          error: action.error.message,
        }
      })
      .addCase(unsubscribeStudent.pending, (state) => {
        return {
          ...state,
        }
      })
      .addCase(unsubscribeStudent.fulfilled, (state, action) => {
        //console.log(action.payload)
        return {
          ...state,
          isSuccess: true,
          //studentData: action.payload.selected,
          error: '',
        }
      })
      .addCase(unsubscribeStudent.rejected, (state, action) => {
        return {
          ...state,
          isSuccess: false,
          error: action.error.message,
        }
      })
  },
})

export default studentSlice.reducer
